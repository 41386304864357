#custom-table thead tr th {
  padding-right: 26px;
  min-width: 100px;
}
#custom-table-body tr {
  border-bottom: #dee2e6;
}
#custom-table-body tr td {
  padding: 4px;
}
#custom-table-body tr:nth-child(odd) td {
  background-color: rgba(0, 0, 0, 0.02);
}
#custom-table-body tr:hover td {
  background-color: rgba(0, 0, 0, 0.04);
}
#custom-table-body tr td button {
  padding: 6px 8px;
}
#custom-table-body tr td button svg {
  font-size: 22px;
}
/* #custom-table-body tr td button svg {

} */
.table-sorting {
  font-size: 10px;
  float: inline-end;
  line-height: 10px;
  position: relative;
  margin-top: 10px;
}
.table-sorting-up {
  opacity: 0.3;
  position: absolute;
  bottom: 0;
}
.table-sorting-down {
  opacity: 0.3;
  position: absolute;
  top: 0;
}
.table-sorting-up.active,
.table-sorting-down.active {
  opacity: 0.6;
}
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.modal-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.modal .btn {
  align-self: flex-end;
}
