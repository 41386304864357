body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* Spinning Hourglass Icon */
.hourglass-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner-icon {
  font-size: 50px;  /* Adjust size of the hourglass icon */
  color: white;     /* Set color of the icon */
  animation: spin 2s linear infinite;  /* Rotate the icon continuously */
}
.dropdown-dark {
  background-color: #343a40 !important; /* Dark background color */
  color: white; /* Text color */
}

.dropdown-dark .dropdown-item {
  color: white; /* Ensure items are white */
}

.dropdown-dark .dropdown-item:hover {
  background-color: #495057; /* Darker hover effect */
}

/* Keyframes for spinning effect */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

