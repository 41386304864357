.modal{
}
.modal-overlay,.modal-overlay-overlay{
    background-color: black;
    opacity: 0.8;
    width: 100%;
    height: 100%;
    z-index: 1;
    position: fixed;
}
.modal-overlay-overlay{
    z-index: 2;
}
.modal-card-wrapper{
    width: 100%;
    height: 100%;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items:first baseline;
    position: fixed;
    overflow-y: scroll;

}
.modal-card{
    /* background-color: #fff;
    border-radius: 4px;
    opacity: 1;
    position: relative; */
    
    margin-top: 2em;
    margin-bottom: 2em;
}
.modal-header{
    padding: 1em 2em;
    border-bottom: 1px solid #ddd;
    display: flex;
    justify-content: space-between;
}
.modal-content{
    padding: 1em 2em;
}
.modal-footer{
    border-top: 1px solid #ddd;
    padding: 1em 2em;
    display: flex;
    justify-content: space-between;
}

#deleteModal.modal-card{
    min-width: 500px;
}
#deleteModal .modal-content{
    padding:2em 2em 3em;
}
#deleteModal .modal-footer{
    justify-content: flex-end;
}
#deleteModal .modal-footer button{
    margin-left: 10px;
}