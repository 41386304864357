a {
  text-decoration: none;
  color: inherit;
}
.error-msg {
  color: red;
}
.lbltheme {
  font-size: 13px;
}
.required,
.lblrequired {
  color: #ff7300;
}
.texttheme,
.themecolor {
  color: #ff7300;
}
.save-icon {
  color: #ff7300;
  font-size: 32px;
}
.btntheme {
  background-color: #ff7300 !important;
  color: #fff !important;
}
.btntheme:hover,
.btntheme:active {
  background-color: #fe5800;
  color: #fff;
  border: 1px solid #fff;
}
.mx-7 {
  margin-left: 7rem !important;
  margin-right: 7rem !important;
}
.ckbtheme {
  background-color: #ddd;
  border-color: gray;
}
.ckbtheme:checked {
  background-color: #ff7300;
  border-color: #ff7300;
}
.error-msg {
  color: red;
}
.toastProgress {
  display: none;
}

.min-width tr th {
  min-width: 100px;
}
.cancel-button {
  background-color: rgb(80, 77, 77);
  color: white;
}

@media screen and (max-width: 768px) {
  .overflow-scrollable {
    overflow-x: scroll;
  }
}
.search-bar-container {
  width: 50%; /* Adjust width as needed */
  position: relative;
}

.search-bar-container .dropdown-menu {
  width: 100%; /* Match the width of the search bar */
  left: 0; /* Align with the search bar */
  top: 100%; /* Position below the search bar */
  z-index: 1050; /* Ensure it appears above other elements */
}

.nav-tabs {
  border-bottom: 2px solid #dee2e6;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  color: #007bff;
  transition: all 0.3s ease;
  cursor: pointer;
}

.nav-tabs .nav-link:hover {
  background-color: #ff7300; /* Light orange for hover */
  border-color: #ff7300;
  color: white; /* White text on hover */
}

.nav-tabs .nav-link.active {
  color: white; /* White text for active tab */
  background-color: #ff7300; /* Active tab background color */
  border-color: #ff7300; /* Border matches the active tab background */
}

.nav-item.active .nav-link {
  font-weight: bold;
}

.card-body {
  padding: 20px;
}

.table-container {
  margin-top: 20px;
}
/* Style for the Example Section */
.card {
  background-color: #fffdfc;
}

.card-body {
  padding: 1.5rem;
}

h6 {
  color: #ff7300;
}

.fw-bold {
  font-weight: bold;
  color: #333;
}

.alert-info {
  background-color: #f0f8ff;
  border-color: #cce5ff;
  color: #31708f;
}

.text-center {
  text-align: center;
}

.mb-3, .mb-4 {
  margin-bottom: 1rem;
}

.mt-3 {
  margin-top: 1rem;
}

.table {
  table-layout: fixed;
  width: 100%;
}

.table th, .table td {
  overflow: hidden;
  white-space: normal; 
  -webkit-line-clamp: 2; /* Number of lines to display before truncating */
  text-overflow: ellipsis; /* Add ellipsis when content overflows */
}

